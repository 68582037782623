<!-- This Vue component is the section on the New Patient and Patient Detail pages that allows the user to enter and edit patient's personal details. -->
<template>
    <b-card class="py-2 personal-card">
        <b-row class="mb-3">
            <b-col>
                <h5 class="heavy text-primary text-uppercase mb-3">{{ t('personal') }}</h5>
            </b-col>
        </b-row>

        <!-- Most of the input fields will be disabled/enabled depending on whether the user has clicked on the Edit button or not.  Input fields that are plaintext are not editable either way, and just act as labels. They might not apply to just one piece of data, so they are it did not necessarily make sense to use HTML label elements. -->
        <b-form class="mx-0 px-0" @reset="onReset">
            <b-form-row>
                <b-col class="pr-sm-3" sm="6" :lg="3">
                    <b-form-group
                        :invalid-feedback="patientIdFeedback || patientIdMaxLengthMsg"
                        :label="t('patientId')"
                        label-for="patient-id"
                        label-class="patient-id-label"
                    >
                        <b-form-input
                            id="patient-id"
                            :disabled="disabledStatus"
                            required
                            @update="validatePatientId()"
                            v-model="workingPatientState.ocosPatientRef"
                            :state="
                                disabledStatus
                                    ? null
                                    : !$v.workingPatientState.ocosPatientRef.$invalid &&
                                      patientIdValid
                            "
                            style="width: 100%"
                            class="custom-input"
                            :class="invalidClassOverride"
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col class="pr-lg-4" sm="6" :lg="3">
                    <!-- 
                                                        <h5 id="label_patient_status" class="mr-1">Patient Status</h5> -->
                    <b-form-group :invalid-feedback="patientIdFeedback" :label="t('patientStatus')">
                        <div id="patient-status-container">
                            <!-- Depending on whether user is in edit mode, this section will display switch-style checkboxes to edit the Active and Priority statuses, or icons and text indicating the current statuses. -->
                            <template v-if="!edit">
                                <div
                                    class="text-caption text-gray-darker mb-3 pt-1"
                                    :class="{heavy: workingPatientState.active}"
                                >
                                    <b-icon
                                        :icon="activeStatusIcon"
                                        class="ml-3"
                                        :class="
                                            workingPatientState.active
                                                ? 'text-success'
                                                : 'text-gray-light'
                                        "
                                        scale="2"
                                    ></b-icon>
                                    {{ t('active') }}
                                </div>
                                <div
                                    class="text-caption text-gray-darker ml-3 pt-1"
                                    :class="{heavy: priorityStatus}"
                                >
                                    <b-icon
                                        :icon="priorityStatusIcon"
                                        class="ml-3"
                                        :class="priorityStatus ? 'text-success' : 'text-gray-light'"
                                        scale="2"
                                    ></b-icon>
                                    {{ t('priority') }}
                                </div>
                            </template>
                            <template v-else>
                                <b-form-checkbox
                                    tabindex="-1"
                                    switch
                                    class="mr-3 custom-checkbox-label"
                                    v-model="workingPatientState.active"
                                >
                                    {{ t('active') }}
                                </b-form-checkbox>
                                <b-form-checkbox
                                    tabindex="-1"
                                    switch
                                    class="custom-checkbox-label"
                                    v-model="priorityStatus"
                                >
                                    {{ t('priority') }}
                                </b-form-checkbox>
                            </template>
                        </div>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <!-- Personal Information section of the card. -->
            <b-form-row>
                <b-col
                    class="pr-sm-3"
                    sm="6"
                    lg="3"
                    v-if="canReadWritePersonalData && !activeCustomer.hidePatientNames"
                >
                    <b-form-group :label="t('firstName')" label-for="input-first-name">
                        <b-form-input
                            id="input-first-name"
                            :disabled="disabledStatus || !canWritePersonalData"
                            ref="firstName"
                            v-model="workingPatientState.firstName"
                            :state="
                                disabledStatus ? null : !$v.workingPatientState.firstName.$invalid
                            "
                            required
                            :class="invalidClassOverride"
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group :label="t('lastName')" label-for="input-last-name">
                        <b-form-input
                            id="input-last-name"
                            :disabled="disabledStatus || !canWritePersonalData"
                            v-model="workingPatientState.lastName"
                            :state="
                                disabledStatus ? null : !$v.workingPatientState.lastName.$invalid
                            "
                            required
                            :class="invalidClassOverride"
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col class="pr-lg-3" sm="6" lg="5">
                    <b-form-row>
                        <b-col :sm="canReadWritePersonalData ? 9 : 12" class="pl-0">
                            <InputsDatePicker
                                ref="InputsDatePicker"
                                v-model="workingPatientState.dateOfBirth"
                                :label="t('dateOfBirth')"
                                :max-date="new Date()"
                                :min-date="dateMin"
                                :disabled="disabledStatus"
                                :state="
                                    !saveAttempted || !$v.workingPatientState.dateOfBirth.$invalid
                                "
                            />
                        </b-col>
                        <b-col sm="3" class="pl-0" v-if="canReadWritePersonalData">
                            <template v-if="!disabledStatus">
                                <b-form-radio-group
                                    :disabled="disabledStatus || !canWritePersonalData"
                                    v-model="workingPatientState.gender"
                                    :required="newPatient"
                                    :options="genderOptions"
                                    stacked
                                    :class="invalidClassOverride"
                                    class="gender-radio-group"
                                >
                                    <b-form-invalid-feedback :state="genderInvalidFeedback">
                                        {{ t('genderError') }}
                                    </b-form-invalid-feedback>
                                </b-form-radio-group>
                            </template>
                            <template v-else>
                                <b-form-group :label="t('gender')" label-for="gender">
                                    <b-form-input
                                        id="gender"
                                        class="pr-0"
                                        :disabled="disabledStatus"
                                        v-model="genderName"
                                    ></b-form-input>
                                </b-form-group>
                            </template>
                        </b-col>
                    </b-form-row>
                    <b-form-group :label="t('surgeon')" label-for="personal-card-surgeon-selector">
                        <Selector
                            id="personal-card-surgeon-selector"
                            required
                            :placeHolder="t('selectSurgeon')"
                            :disabled="disabledStatus || !this.hasSurgeonsPermission()"
                            :options="surgeonsOptions"
                            optionValueKey="doctorId"
                            optionDisplayKey="optionDisplayText"
                            optionDisabledKey="optionDisabled"
                            v-model="workingPatientState.doctorId"
                            :class="surgeonSelectorClasses"
                            :state="
                                disabledStatus || invalidClassOverride
                                    ? null
                                    : !$v.workingPatientState.doctorId.$invalid
                            "
                            :propertiesToSearch="['optionDisplayText']"
                            @search-enter="goNextInput($event, 3)"
                        >
                            <template #option="{option}">
                                <div class="personal-card-surgeon-selector-option">
                                    <SearchedText
                                        class="ml-1"
                                        :disabled="option['optionDisabled']"
                                        :searchedItem="option"
                                        searchedProperty="optionDisplayText"
                                    ></SearchedText>
                                </div>
                            </template>
                            <template #no-options-found> No surgeons found </template>
                        </Selector>
                    </b-form-group>
                </b-col>

                <b-col :lg="canReadWritePersonalData && !activeCustomer.hidePatientNames ? 4 : 7">
                    <b-form-group>
                        <template #label>
                            <div class="d-flex justify-content-between">
                                <span class="label">{{ t('notes') }}</span>
                                <span class="text-caption text-gray-darker">
                                    {{ noteLength }}/200
                                </span>
                            </div>
                        </template>
                        <b-form-textarea
                            id="customNoteField"
                            :disabled="disabledStatus"
                            v-model="workingPatientState.patientNote"
                            rows="5"
                            maxlength="200"
                            :class="{
                                invalidClassOverride: invalidClassOverride,
                                'is-valid': (newPatient || edit) && noteLength > 0,
                            }"
                        >
                        </b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <!-- Edit/Cancel/Save Buttons -->
            <div id="buttonBar">
                <template v-if="edit == false && newPatient == false">
                    <b-button
                        type="button"
                        class="ml-1"
                        variant="primary"
                        :disabled="loading"
                        @click="toggleEdit"
                        v-if="canEdit"
                        >{{ t('edit') }}</b-button
                    >
                </template>
                <template v-else>
                    <b-button type="reset" variant="secondary" :disabled="loading">{{
                        t('cancel')
                    }}</b-button>
                    <b-button
                        type="button"
                        variant="primary"
                        class="ml-3"
                        :disabled="loading"
                        @click="onSubmit"
                        >{{ t('save') }}
                    </b-button>
                </template>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import {required, requiredIf, maxLength, helpers} from 'vuelidate/lib/validators';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import get from 'lodash/get';
import od_icon from '@/assets/od_icon.svg';
import os_icon from '@/assets/os_icon.svg';
import {ZoneCodes} from '@/constants/zone';
import {surgeonIdNameFormat} from '@/utilities/formatters';
import Selector from '@/components/Selector';
import SearchedText from '@/components/SearchedText.vue';
import InputsDatePicker from '@/components/InputsDatePicker.vue';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const MIN_ALLOWABLE_YEAR = 120;

export default {
    name: 'PersonalCard',
    components: {Selector, SearchedText, InputsDatePicker},
    data() {
        return {
            ZoneCodes,
            OrionApiPIMap: ['RefractiveSurgery', 'NonrefractiveSurgery', 'IolCleCataract'],

            odIcon: od_icon,
            osIcon: os_icon,

            edit: false,
            patientIdValid: true,

            /* Initially, the assigned doctor may be invalid. That info is passed from the parent component as a prop.  However, once (if) the user changes the doctor, then the doctor selection should then be valid.  Since props should not be modified, this data value stores the updated validity state. */
            updatedIsDoctorValid: true,

            /* Input field feedback message for if the Patient ID field is invalid/duplicate. */
            patientIdFeedback: '',

            priorityStatus: false,
            patientStatusOptions: ['Active', 'Priority'],

            /* This initialPatientState is a copy of the patient info that will be reverted to if the user cancels the edit. */
            initialPatientState: {},

            /* This workingPatientState is a copy of the patient info that will then be committed if the user clicks the save button.  It is needed because if the input fields modeled the patientState prop, the header bar would be modified whenever the user changed any of the personal data fields. */
            workingPatientState: {},

            /* Separate value for the initial priority status because the slider button takes a Boolean value, but priority is stored as an integer in the data. */
            initialPriorityStatus: false,

            saveAttempted: false,
            loading: false,
        };
    },

    // Fields for Vuelidate to validate input against
    validations: {
        workingPatientState: {
            firstName: {},
            lastName: {},
            ocosPatientRef: {
                required,
                ascii: helpers.regex('ascii', /^[a-zA-Z0-9-]+$/),
                maxLength: maxLength(15),
            },
            gender: {
                required: requiredIf(function () {
                    return this.canWritePersonalData;
                }),
            },
            dateOfBirth: {
                required,
            },
            doctorId: {
                required,
            },
        },
    },

    props: {
        /* Patient state data as passed from parent. */
        patientState: {
            default: {},
        },

        /* Indicates whether the user is adding a new patient or not. */
        newPatient: {
            default: false,
        },

        /* Specified whether the card should start in edit mode.  Cases where this would be true include when the user is on the new patient page and when the user navigates to patient details in edit mode from the patient list table. */
        startInEditMode: {
            default: false,
        },

        /* Specifies whether the doctor that this patient starts with is a valid doctor in the doctor list. */
        isDoctorValid: {
            default: true,
        },
    },
    beforeDestroy() {
        this.tabControlInputsListeners();
    },
    mounted() {
        this.priorityStatus = Boolean(this.patientState.priority);
        this.initialPriorityStatus = Boolean(this.patientState.priority);

        this.workingPatientState = JSON.parse(JSON.stringify(this.patientState));

        if (this.newPatient) {
            this.edit = true;
            this.workingPatientState.gender = null;
            this.workingPatientState.doctorId = this.activeDoctorId;
        }

        if (this.startInEditMode) {
            this.edit = true;
            this.saveInitialPatientState();
        }

        this.updatedIsDoctorValid = this.isDoctorValid;
        this.tabControlInputsListeners(true);
    },
    methods: {
        ...mapActions('user', ['setActiveDoctorId']),
        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
        }),
        surgeonIdNameFormat,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        /* Validate patient ID field to make sure it's not a duplicate of any other existing patient id */
        async validatePatientId() {
            try {
                // If the ID is empty to start off, the ID is not valid
                if (this.workingPatientState.ocosPatientRef === '') {
                    this.patientIdFeedback = '';
                    return false;
                }

                //if > 18, then don't do any more checking, but let vuelidate handle it
                if (
                    this.workingPatientState.ocosPatientRef.length >
                    this.$v.workingPatientState.ocosPatientRef.$params.maxLength.max
                ) {
                    this.patientIdValid = true;
                    this.patientIdFeedback = this.patientIdMaxLengthMsg;
                    return false;
                }

                let result = await this.$store.dispatch('patient/validatePatientId', {
                    patientId: this.workingPatientState.patientId,
                    ocosPatientRef: this.workingPatientState.ocosPatientRef,
                });

                this.patientIdValid = result.success;

                if (result.success) {
                    this.patientIdFeedback = '';
                } else {
                    this.patientIdFeedback = get(
                        result,
                        'error.errors.0',
                        this.t('patientIdAlreadyUsed')
                    );
                }
                return result.success;
            } catch (err) {
                console.log('An error has been caught', err.response);
            }
        },

        /* Attempt to save the new patient, and then if successful, wait for the new patient ID and reroute to that patient's detail page. */
        async waitForNewPatientId() {
            try {
                // Check the patient ID one more time before saving, and return if it's not valid
                await this.validatePatientId();

                if (!this.patientIdValid) {
                    //No need to display an alert as the form validation will show the error.
                    return;
                }

                let result = await this.$store.dispatch(
                    'patient/saveNewPatient',
                    this.workingPatientState
                );

                if (this.workingPatientState.doctorId == 0) {
                    this.$router.push({
                        name: 'PatientDetails',
                        params: {patientId: result},
                    });
                    return;
                }

                await this.$router.push({
                    name: 'PatientDetails',
                    params: {patientId: result},
                });

                if (
                    this.checkPermissions({
                        [PERMISSIONS.PATIENT_PREOP_DATA]: PERMISSIONS_VALUES.READ_WRITE,
                    }) &&
                    this.workingPatientState.doctorId != 0
                ) {
                    this.$router.push({
                        name: 'PreOpData',
                        params: {
                            patientId: result,
                        },
                    });
                }
            } catch (err) {
                console.log('An error has been caught', err.response);
            }
        },

        /* Attempt to save the updates to the patient's info, and if successful, toggle back to read-only mode. */
        async waitForPatientUpdate() {
            try {
                // Check the patient ID one more time before saving, and return if it's not valid
                await this.validatePatientId();
                if (!this.patientIdValid) {
                    //No need to display an alert as the form validation will show the error.
                    return;
                }

                await this.$store.dispatch('patient/updatePatient', this.workingPatientState);

                if (this.hasSurgeonsPermission()) {
                    // Fetch the doctors list and patient again because the patient
                    // request returns doctor info that may have changed since the
                    // last request. Fetching both of them helps keep the data in sync.
                    await this.$store.dispatch('doctors/fetchList', {
                        currentPage: 1,
                        perPage: 0, // fetch all the doctors
                    });
                }

                await this.$store.dispatch('patient/fetchPatient', {
                    patientId: this.workingPatientState.patientId,
                });
                this.workingPatientState = JSON.parse(JSON.stringify(this.patient));

                this.toggleEdit();
                this.priorityStatus = Boolean(this.workingPatientState.priority);

                /* If the doctor was initially invalid, then check to see if the doctor ID has changed after the update. If it has, then that has to mean that now the doctor is valid, because the only other options in the list are valid doctors. */
                if (
                    this.updatedIsDoctorValid == false &&
                    this.workingPatientState.doctorId != this.initialPatientState.doctorId
                ) {
                    this.updatedIsDoctorValid = true;
                }
            } catch (err) {
                console.log('An error has been caught');
                alert(err);
            }
        },

        /**
         * Either create and save a new patient, or update the current patient's
         * data, depending on the parent component.
         * */
        async onSubmit() {
            this.saveAttempted = true;

            // Setting hasPreviousInterventions to false by default since was deprecated
            this.workingPatientState.hasPreviousInterventions = false;

            if (this.$v.workingPatientState.$invalid || !this.$refs.InputsDatePicker.isValid) {
                return;
            }

            this.workingPatientState.priority = Number(this.priorityStatus);

            // Need to manually set the doctor's full name, because this field
            // is not included in the patient api endpoint. Since the page
            // header is modeled to the doctor's full name, the header
            // information won't automatically be updated if we don't set this
            // value here. */
            this.workingPatientState.doctorName = this.doctorName;

            try {
                this.loading = true;
                if (this.newPatient) {
                    await this.waitForNewPatientId();
                } else {
                    await this.waitForPatientUpdate();
                }
            } finally {
                this.loading = false;
            }

            // If there is an active surgeon and the selected surgeon is not
            // the active surgeon, clear the active surgeon.
            if (this.activeDoctorId && this.workingPatientState.doctorId !== this.activeDoctorId) {
                await this.setActiveDoctorId(null);
                // Clear active surgeon from patientList search if was previusly stored
                const payload = JSON.parse(localStorage.getItem('pl-query-params'));
                if (payload) {
                    delete payload.surgeon;
                    localStorage.setItem('pl-query-params', JSON.stringify(payload));
                }
            }
        },

        /* Clear data back to beginning edit state. If not new patient page, toggle back to read-only mode. */
        onReset(event) {
            event.preventDefault();
            this.resetPatientState();

            this.patientIdFeedback = '';

            if (!this.newPatient) {
                this.toggleEdit();
            } else {
                this.saveAttempted = false;
            }
        },

        /* Toggle between edit and read-only mode. If going to edit mode, save a copy of the initial patient data, so that it can be reverted if the user cancels the edit. */
        toggleEdit(event) {
            if (this.edit == false) {
                //Since Edit and Cancel buttons are in the same position, there was a "click-through" where the newly-toggled Cancel button would also receive the click, immediately switching back to read-only mode. So here the event is prevented.
                event.preventDefault();
                this.saveInitialPatientState();
            }
            this.edit = !this.edit;
        },

        /* Save patient info state before toggling to edit, so that it can be restored if the user cancels the edit. */
        saveInitialPatientState() {
            this.initialPatientState = JSON.parse(JSON.stringify(this.workingPatientState));
            this.initialPriorityStatus = this.priorityStatus;
        },

        /* Revert to data state before the edit. */
        resetPatientState() {
            this.priorityStatus = this.initialPriorityStatus;
            this.patientIdValid = true;
            let patientStateObj = JSON.parse(JSON.stringify(this.initialPatientState));

            if (this.newPatient) {
                /* Default initial active state for a new patient is true. */
                patientStateObj.active = true;

                /* In a new patient, reset the surgeon options to blank */
                patientStateObj.doctorId = null;
            }

            this.workingPatientState = patientStateObj;
            this.$refs.InputsDatePicker.reset();
        },

        /* Return the appropriate color for the Active and Priority icons */
        statusColor: function (status) {
            if (status || status == 1) {
                return 'color: green';
            } else {
                return 'color: #C2C9D1';
            }
        },

        inputElements() {
            return Array.from(
                this.$el.querySelectorAll(
                    '.personal-card input[type=text], .personal-card textarea, #input-dob'
                )
            );
        },

        tabControlInputsListeners(register) {
            if (register) {
                this.inputElements().forEach((input) =>
                    input.addEventListener('keydown', this.goNextInput)
                );
            } else {
                this.inputElements().forEach((input) =>
                    input.removeEventListener('keydown', this.goNextInput)
                );
            }
        },

        goNextInput(event, index) {
            // key code for enter, tab, shift
            const isTabulationClick = [9, 13].includes(event.keyCode);
            if (isTabulationClick || (event.shiftKey && isTabulationClick)) {
                event.preventDefault();
                const inputElements = this.inputElements().filter((input) => !input.disabled);
                index = index ? index : inputElements.findIndex((input) => input === event.target);
                // if shiftKey pressed go before input otherwise go next input
                event.shiftKey ? index-- : index++;
                //if last, jump to first one
                if (index > inputElements.length - 1) index = 0;
                //if first, jump to last one
                else if (index < 0) index = inputElements.length - 1;

                inputElements[index]?.focus();
            }
        },

        hasSurgeonsPermission() {
            return this.checkPermissions({
                [PERMISSIONS.SURGEONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                ],
            });
        },
    },

    computed: {
        ...mapState({
            patient: (state) => state.patient.currentPatient,
        }),

        ...mapGetters({doctors: 'doctors/list'}),

        ...mapGetters('user', ['activeDoctorId', 'activeCustomer', 'currentUser']),

        ...mapGetters('permissions', ['permissions']),

        ...mapGetters('zone', ['currentZone']),

        canEdit() {
            return this.checkPermissions({
                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
            });
        },

        canReadWritePersonalData() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PERSONAL_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            });
        },

        canWritePersonalData() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PERSONAL_DATA]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },

        isOUSZone() {
            return this.currentZone === ZoneCodes.OUS;
        },

        initialPage() {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            return {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
                date,
            };
        },

        dateMin() {
            const date = new Date();
            date.setFullYear(date.getFullYear() - MIN_ALLOWABLE_YEAR);
            return date;
        },

        /* Return the full text name of the selected gender, rather than just the single-letter abbreviation. */
        genderName: function () {
            if (this.workingPatientState.gender == 'M') {
                return this.t('genderMale');
            } else if (this.workingPatientState.gender == 'F') {
                return this.t('genderFemale');
            } else if (this.workingPatientState.gender == 'O') {
                return this.t('genderOther');
            } else {
                return '';
            }
        },
        genderOptions: function () {
            return [
                {text: this.t('genderMale'), value: 'M'},
                {text: this.t('genderFemale'), value: 'F'},
                {text: this.t('genderOther'), value: 'O'},
            ];
        },
        /* Return the display name for the patient's doctor. */
        doctorName: function () {
            if (!this.updatedIsDoctorValid) {
                /* The doctor ID might have been changed, but the validity just not updated yet. If it hasn't changed yet,
                then the invalid doctor is still selected, so return the name as is. */
                if (this.workingPatientState.doctorId === this.initialPatientState.doctorId) {
                    return this.workingPatientState.doctorName;
                }
            }

            /* Otherwise search through the list of valid doctors to get the doctor's name */
            for (var i = 0; i < this.doctors.length; i++) {
                if (this.workingPatientState.doctorId == this.doctors[i].doctorId) {
                    return this.doctors[i].firstName + ' ' + this.doctors[i].lastName;
                }
            }
            return '';
        },

        /* Returns the appropriate icon for the Active status, checkmark for active and x for inactive. */
        activeStatusIcon: function () {
            if (this.workingPatientState.active) return 'check';
            else return 'x';
        },

        /* Returns the name of the appropriate icon for the Priority status, checkmark for priority and x for non-priority. */
        priorityStatusIcon: function () {
            if (this.priorityStatus) return 'check';
            else return 'x';
        },

        /* Returns Boolean indicating whether editable fields should be disabled or not (true if the card is in edit mode, false otherwise). */
        disabledStatus: function () {
            return !this.edit;
        },

        /* Indicates the current length of the note in the text field. Value will be displayed to user along with max allowed length. */
        noteLength: function () {
            return this.workingPatientState.patientNote
                ? this.workingPatientState.patientNote.length
                : 0;
        },

        /* Return the list of doctors for the selector. */
        surgeonsOptions: function () {
            let surgeonsOptionsLocal = this.hasSurgeonsPermission()
                ? JSON.parse(JSON.stringify(this.doctors))
                : [
                      {
                          doctorId: this.workingPatientState.doctorId,
                          ocosDoctorId: this.workingPatientState.ocosDoctorId,
                          firstName: this.workingPatientState.doctorFirstName,
                          lastName: this.workingPatientState.doctorLastName,
                          active: this.workingPatientState.doctorActive,
                      },
                  ];

            surgeonsOptionsLocal.forEach((s) => {
                s['optionDisplayText'] = this.surgeonIdNameFormat(
                    s?.ocosDoctorId || 'N/A',
                    `${s?.firstName || 'N/A'} ${s?.lastName || 'N/A'}`,
                    s.active
                );
                s['optionDisabled'] = false;
            });

            surgeonsOptionsLocal.unshift({
                doctorId: 0,
                optionDisplayText: this.t('noSurgeon'),
                optionDisabled: this.patient?.ocosDoctorId ? true : false,
            });

            if (!this.updatedIsDoctorValid && this.workingPatientState.doctorId != '0') {
                surgeonsOptionsLocal.push({
                    doctorId: this.patient.doctorId,
                    optionDisplayText: this.surgeonIdNameFormat(
                        this.patient?.ocosDoctorId || 'N/A',
                        this.patient?.doctorName || 'N/A',
                        this.isDoctorValid
                    ),
                    optionDisabled: true,
                });
            }

            return surgeonsOptionsLocal;
        },

        surgeonSelectorClasses: function () {
            return {
                invalidClassOverride: this.invalidClassOverride,
                'is-valid': !this.$v.workingPatientState.doctorId.$invalid,
                'is-invalid': this.$v.workingPatientState.doctorId.$invalid,
            };
        },

        invalidClassOverride: function () {
            return this.newPatient && !this.saveAttempted ? 'invalidClassOverride' : '';
        },

        genderInvalidFeedback: function () {
            if (this.disabledStatus) {
                return null;
            }

            if (this.saveAttempted) return !this.$v.workingPatientState.gender.$invalid;

            return null;
        },

        patientIdMaxLengthMsg() {
            return !this.$v.workingPatientState.ocosPatientRef.maxLength
                ? this.t('patientIdMaxLengthValidation', {
                      length: this.$v.workingPatientState.ocosPatientRef.$params.maxLength.max,
                  })
                : '';
        },
    },
};
</script>

<style lang="scss">
.personal-card-surgeon-selector-option {
    display: flex;
    align-items: center;
}
</style>

<style lang="scss" scoped>
.disabled-date-picker.form-control {
    pointer-events: none;
    background: #f6f6f6;
}

.form-control.is-invalid,
.form-control.is-valid {
    background-image: none;
}

.form-control.is-invalid.invalidClassOverride,
textarea#customNoteField {
    border-color: #e0e4e8;
}

textarea {
    color: black;
}

input:focus,
textarea:focus {
    box-shadow: none;
}

.patient-detail .form-control:disabled {
    background-color: #f6f6f6;
}

.patientOptionsImg {
    float: left;
    margin-right: 10px;
}

#patientOptionsContainer {
    padding-bottom: 0px;
}

.patientOptionGroup {
    padding-bottom: 5px;
}

.patientOptionGroup {
    line-height: 1rem;
}

#customNoteField {
    height: 100px;
}

.piFormGroup {
    margin-bottom: 10px;
}

#buttonBar {
    margin: 10px 0px 10px 3px;
}

::v-deep .gender-radio-group label span {
    margin-top: 3px;
    display: block;
}

::v-deep .vc-title {
    padding: 4px 8px;
    border-radius: 8px;
    border-width: 2px;
    border-color: transparent;
    background-color: rgba(204, 214, 224, 0.3);
}
</style>
